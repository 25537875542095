<!-- =========================================================================================
    File Name: CarouselPagination.vue
    Description: Carousel with pagination
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card class="carousel-example">
          <!-- swiper -->
          <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
            <swiper-slide v-for="(item, index) in bannerDetails" :key="index">
              <div class="slider-item-container">
                <div class="slider-item-content-container">
                  <h1>{{item.subtitle}}</h1>
                  <div class="slider-item-title">
                    {{item.title}}
                  </div>
                  <div class="slider-item-description">
                    {{item.description}}
                  </div>
                  <div v-if="item.btnText" class="slider-item-button">
                    <a :href="item.btnActionUrl" class="generic-button">{{item.btnText}}</a>
                  </div>
                </div>
                <img v-if="item.photoUrl" class="responsive banner-img" :src="item.photoUrl" alt="banner">
                <img v-else class="responsive banner-img" src="https://healminder-media.s3.amazonaws.com/banner-default.png" style="height: 550px;" alt="banner">
              </div>
            </swiper-slide>
<!--            <swiper-slide>-->
<!--              <div class="slider-item-container">-->
<!--                <div class="slider-item-content-container">-->
<!--                  <h1>{{$t('maternity.bannerSubtitle')}}</h1>-->
<!--                  <div class="slider-item-title">-->
<!--                    {{$t('maternity.bannerTitle')}}-->
<!--                  </div>-->
<!--                  <div class="slider-item-description">-->
<!--                    {{$t('maternity.bannerDescription')}}-->
<!--                  </div>-->
<!--                  <div class="slider-item-button">-->
<!--                    <button class="generic-button">{{$t('maternity.bannerButton')}}</button>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <img class="responsive banner-img" src="@/assets/images/pages/carousel/banner.jpg" alt="banner">-->
<!--              </div>-->
<!--            </swiper-slide>-->
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>

          <template slot="codeContainer">
&lt;template&gt;
    &lt;div class=&quot;carousel-example&quot; :dir=&quot;$vs.rtl ? 'rtl' : 'ltr'&quot; :key=&quot;$vs.rtl&quot;&gt;
        &lt;swiper :options=&quot;swiperOption&quot;&gt;
            &lt;swiper-slide&gt;
              &lt;img class=&quot;responsive&quot; src=&quot;@/assets/images/pages/carousel/banner-16.jpg&quot; alt=&quot;banner&quot;&gt;
            &lt;/swiper-slide&gt;
            &lt;swiper-slide&gt;
              &lt;img class=&quot;responsive&quot; src=&quot;@/assets/images/pages/carousel/banner-19.jpg&quot; alt=&quot;banner&quot;&gt;
            &lt;/swiper-slide&gt;
            &lt;swiper-slide&gt;
              &lt;img class=&quot;responsive&quot; src=&quot;@/assets/images/pages/carousel/banner-5.jpg&quot; alt=&quot;banner&quot;&gt;
            &lt;/swiper-slide&gt;
            &lt;swiper-slide&gt;
              &lt;img class=&quot;responsive&quot; src=&quot;@/assets/images/pages/carousel/banner-9.jpg&quot; alt=&quot;banner&quot;&gt;
            &lt;/swiper-slide&gt;
            &lt;div class=&quot;swiper-pagination&quot; slot=&quot;pagination&quot;&gt;&lt;/div&gt;
        &lt;/swiper&gt;
    &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
    data() {
        return {
            swiperOption: {
                pagination: {
                    el: '.swiper-pagination'
                }
            }
        }
    },
    components: {
        swiper,
        swiperSlide
    }
}
&lt;/script&gt;
          </template>
      </vx-card>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  data () {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  },
  components: {
    swiper,
    swiperSlide
  },
  computed: {
    bannerDetails() {
      return this.$store.state.settings.organizationDetails.custom_visual_banner_details ?
        JSON.parse(this.$store.state.settings.organizationDetails.custom_visual_banner_details) :
        [{"photoUrl": "","subtitle": "WHAT'S NEW", "title": "PREPARATION FOR BREASTFEEDING", "description": "A guide to breastfeeding before childbirth", "btnText": "JOIN NOW","btnActionUrl": "/"}]
    }
  }
}

</script>
<style lang="scss">
.vx-card__body {
  padding: 0 !important;
}
.slider-item-container {
  position: relative;
  &:before {
    background-color: rgba(17,17,17,.85);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.4;
    content: '';
  }
  .slider-item-content-container {
    min-width: 800px;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    h1 {
      color: #FFE9B3;
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    .slider-item-title {
      color: var(--lGreen);
      font-size: 37px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 15px;
      text-align: center;
    }
    .slider-item-description {
      color: var(--lGreen);
      font-size: 18px;
      margin-bottom: 25px;
    }
    .generic-button {
      padding-left: 35px;
      padding-right: 35px;
      color: white;
    }
  }
}
@media (max-width: 1250px) {
  .slider-item-container {
    .slider-item-content-container {
      h1 {
        font-size: 14px;
        margin-bottom: 20px;
      }

      .slider-item-title {
        font-size: 28px;
        margin-bottom: 20px;
      }

      .slider-item-description {
        margin-bottom: 20px;
      }
    }
  }
}
@media (max-width: 980px) {
  .slider-item-container {
    height: 320px;
    .slider-item-content-container {
      h1 {
        font-size: 14px;
        margin-bottom: 15px;
      }
      .slider-item-title {
        font-size: 22px;
        margin-bottom: 15px;
      }
      .slider-item-description {
        margin-bottom: 15px;
      }
      .generic-button {
        padding: 15px 20px;
      }
    }

    .banner-img {
      height: 320px;
      object-fit: cover;
    }
  }
}
@media (max-width: 768px) {
  .slider-item-container {
    .slider-item-content-container {
      h1 {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .slider-item-title {
        font-size: 18px;
        margin-bottom: 10px;
      }
      .slider-item-description {
        font-size: 14px;
        margin-bottom: 10px;
        text-align: center;
      }
      .generic-button {
        padding: 12px 15px;
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 480px) {
  .slider-item-container {
    .slider-item-content-container {
      h1 {
        font-size: 15px;
        margin-bottom: 8px;
      }
      .slider-item-title {
        font-size: 18px;
        margin: 0 0 8px;
      }
      .slider-item-description {
        font-size: 14px;
        margin-bottom: 8px;
        text-align: center;
      }
      .generic-button {
        padding: 8px 10px;
        font-size: 12px;
        border-radius: 4px;
      }
    }
  }
}
</style>
