<template>
  <div>
    <h2 class="services-title">
      {{$t('landingPage.recommendedClasses')}}
    </h2>
    <card-list-carousel :items="maternityNewServices" :options="options"></card-list-carousel>
  </div>
</template>

<script>
import CardListCarousel from '@/components/service-carousel/CardListCarousel.vue';

export default {
  name: "recommended-classes-list-carousel",
  components: {
    CardListCarousel
  },
  props: {
    items: [],
  },
  data() {
    return {
      options: {
        rewind : true,
        width  : '100%',
        perPage: 3,
        gap    : '35px',
        perMove: 1,
        pagination: false,
        breakpoints: {
          1200: {
            perPage: 2,
          },
          480: {
            perPage: 1,
          },
        }
      }
    };
  },
  created() {
    this.$store.dispatch('careplan/fetchNewService');
  },
  computed: {
    maternityNewServices() {
      return this.$store.state.careplan.maternityNewServices;
    }
  },
};
</script>

<style scoped lang="scss">
</style>
