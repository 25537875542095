<!-- =========================================================================================
  File Name: .vue
  Description: eCommerce Item Detail page
========================================================================================== -->

<template>
  <div class="maternity-registration-container">
    <carousel-pagination/>
    <div class="maternity-registration-services-container">
      <section class="maternity-registration-services">
        <service-list-carousel v-if="organizationDetails.custom_listing_of_new_items" :practioners="practioners"></service-list-carousel>
      </section>
      <section class="recommended-classes-services">
        <recommended-classes-list-carousel v-if="organizationDetails.custom_listing_recommendations" :practioners="practioners"></recommended-classes-list-carousel>
      </section>
      <section v-if="organizationDetails.custom_listing_addtional_recommendations" class="join-classes-services">
        <h2 class="services-title classes-title">
          {{$t('landingPage.mayJoinClasses')}}
        </h2>
        <classes-list-carousel :practioners="practioners"></classes-list-carousel>
      </section>
    </div>
  </div>
</template>
<script>

import Prism from "vue-prism-component";
import ServiceListCarousel from '@/components/service-carousel/ServiceListCarousel.vue';
import RecommendedClassesListCarousel from '@/components/service-carousel/RecommendedClassesListCarousel.vue';
import CarouselPagination from '@/views/components/extra-components/carousel/CarouselPagination.vue';
import ClassesListCarousel from "@/components/service-carousel/ClassesListCarousel";
import axios from "axios";

let req = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
})

export default {
  components: {
    Prism,
    CarouselPagination,
    ServiceListCarousel,
    RecommendedClassesListCarousel,
    ClassesListCarousel
  },
  data() {
    return {
      treatmentSearchQuery: "",
      //Broken Tooth,Emergency, Extraction,Implant Consultation, Teeth Dentistry
      bannerImg:
        "https://healthminder-dental-website.s3.ap-southeast-1.amazonaws.com/Background%20%26%20Images/HMDentalCare_TreatmentsTopImg.png",
      error_occured: false,
      error_msg: "",
      practioners: [],
    };
  },
  mounted() {
    // this.fetchPractioners()
    console.log("practioners ", process.env.VUE_APP_FRAPPE_CLIENT_ID)
  },
  methods: {
    fetchPractioners() {
      req({
        url: '/api/resource/Healthcare Practitioner?fields=["*"]',
        method: 'get',
        headers: {
          'Authorization': `token ${process.env.VUE_APP_FRAPPE_CLIENT_ID}:${process.env.VUE_APP_FRAPPE_CLIENT_SECRET}`
        }
      }).then(res => {
        this.practioners = res.data.data
      })
    }
  },
  computed: {
    maternityNewServices() {
      return this.$store.state.careplan.maternityNewServices;
    },
    organizationDetails() {
      return this.$store.state.settings.organizationDetails
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
.maternity-registration-services-container {
  margin: 0 auto 100px;
  max-width: 1400px;
  .services-title {
    //color: $green;
    color: var(--labelColor);
    padding-left: 80px;
    margin: 90px 0 30px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .join-classes-services {
    .classes-title {
      margin: 180px 0 60px;
      text-align: center;
      //color: $lightGreen;
      color: var(--labelColor);
      font-size: 32px;
    }
  }
}
@media (max-width: 1000px) {
  .maternity-registration-services-container {
    padding: 0 20px;
    .services-title {
      font-size: 16px;
      margin-bottom: 20px;
      padding-left: 60px;
    }
  }
}
@media (max-width: 600px) {
  .maternity-registration-services-container {
    padding: 0;
    .services-title {
      margin-top: 50px;
      padding-left: 50px;
      font-size: 14px;
      margin-bottom: 15px;
    }
    .join-classes-services {
      .classes-title {
        padding: 0;
        margin: 80px 0 30px;
        font-size: 20px;
      }
    }
  }
}
</style>
